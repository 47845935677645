@import "components/layout/variables.scss";

.page-about {
  padding: 24px 0;

  @include breakpoint(md) {
    padding-top: 72px;
  }

  @include breakpoint(lg) {
    padding-top: 120px;
  }

  &__picture {
    display: flex;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  &__description p {
    user-select: none;
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 200;
    text-align: justify;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
