@import "components/layout/variables.scss";

.page-project {
  padding: 72px 0 24px;
  position: relative;

  &__project-navigation {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 38px;
    left: 16px;
    right: 16px;
    transform: translateY(-50%);

    .prev,
    .next {
      border: 0;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: rgba($color: #555, $alpha: 0.75);
      border-radius: 4px;
      cursor: pointer;

      &:before {
        font-family: swiper-icons;
        content: "prev";
        display: block;
        color: #fff;
        font-size: 20px;
      }
    }

    .next:before {
      content: "next";
    }
  }

  @include breakpoint(lg) {
    padding-top: 120px;

    &__project-navigation {
      top: 62px;
    }
  }

  &__slide-flex {
    display: flex;

    > div,
    > div img {
      height: 100%;
    }

    > div + div {
      margin-left: 32px;
    }
  }
}
