@import "components/layout/variables.scss";

.page-contact {
  padding: 24px 0;
  user-select: none;

  &__title {
    margin: 0 0 32px 0;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    display: none;
  }

  &__contact-data p {
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 200;
    text-align: justify;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__picture {
    display: none;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }

    @include breakpoint(md) {
      display: flex;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    label {
      user-select: none;
      margin: 0 0 8px 0;
      font-size: 16px;
      font-weight: 200;
      text-transform: uppercase;
      cursor: pointer;
    }

    input,
    textarea,
    button {
      margin: 0;
      padding: 12px;
      background-color: #fff;
      color: #111;
      border: 1px solid #000;
      border-radius: 0;
      box-shadow: none;
      font-size: 16px;
      font-weight: 200;
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out,
        border-color 200ms ease-in-out;

      &[disabled] {
        background-color: #ddd;
        border-color: #aaa;
      }

      &:focus {
        outline: none;
        border-color: #aaa;
      }
    }

    input {
      margin-bottom: 36px;
    }

    textarea {
      min-height: 150px;
    }

    &__footer {
      margin-top: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      p {
        flex: 1;
        margin: 0 0 0 24px;
        padding: 12px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 200;
        text-align: center;

        &.success {
          background-color: #e3ffef;
          border: 1px solid #1fee79;
        }

        &.failure {
          background-color: #ffe5e3;
          border: 1px solid #ee371f;
        }
      }
    }

    button {
      padding: 12px 32px;
      text-transform: uppercase;

      &:hover,
      &:focus {
        color: #777;
        border-color: #aaa;
      }
    }
  }
}
