@import "components/layout/variables.scss";

.project {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  user-select: none;

  &__title {
    margin: 0 0 32px 0;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
  }

  &__subtitle {
    margin: 0 0 12px 0;
    font-size: 12px;
    font-weight: 200;
    text-transform: uppercase;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-bottom: 16px;
  }

  &__attributes,
  &__description {
    flex-basis: 50%;
  }

  &__description {
    padding-top: 36px;
  }

  @include breakpoint(md) {
    &__details {
      flex-direction: row;
      align-items: flex-start;
    }

    &__attributes {
      padding-right: 36px;
    }

    &__description {
      padding-top: 0;
      padding-right: 0;
    }
  }

  &__attributes {
    display: grid;
    grid-template-columns: auto 1fr;

    &__key,
    &__value {
      margin: 0;
      padding: 0 0 20px 0;
      &.last {
        padding-bottom: 0;
      }
    }

    &__key {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
    }

    &__value {
      padding-left: 20px;
      font-size: 16px;
      font-weight: 200;
    }
  }

  &__description p {
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 200;
    text-align: justify;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__image-wrapper {
    display: inline-flex;
    width: 100%;
    user-select: none;
    overflow: hidden;
    position: relative;

    div {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: #77777730;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    p {
      display: inline-flex;
      position: absolute;
      padding: 8px 16px;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: uppercase;
      background-color: #292525f0;
      color: white;
      font-size: 12px;
      font-weight: 400;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    &:hover {
      div {
        opacity: 1;
      }

      p {
        opacity: 1;
      }

      img {
        transform: scale(1.1);
      }
    }
  }

  &__image {
    display: inline-flex;
    width: 100%;
    object-fit: cover;
    user-select: none;
    transition: transform 300ms ease-in-out;
  }

  &--is-interactive {
    cursor: pointer;
  }
}
