@import "components/layout/variables.scss";

.layout {
  min-height: 100vh;
  background-color: $background-color;
  padding: 0 24px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    min-height: 100vh;
    position: relative;

    @include breakpoint(lg) {
      margin: 0 auto;
      width: calc(map-get($screen, lg) - 48px);
    }
  }

  &__header {
    padding-top: 32px;
    background-color: $background-color;

    &--is-sticky {
      position: sticky;
      top: 0;
    }
  }

  &__header &__header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__brand-logo {
    display: block;
    position: relative;
    width: 150px;
    height: 36px;

    img {
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: width 150ms ease-in-out;
    }

    &:hover img {
      width: 96%;
    }
  }

  &__scroll-top {
    position: fixed;
    right: 40px;
    bottom: 40px;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: rgba($color: #555, $alpha: 0.75);
    border-radius: 4px;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: opacity 150ms ease-in-out;

    &--is-visible {
      pointer-events: initial;
      opacity: 1;
    }

    &:before {
      font-family: swiper-icons;
      content: "next";
      display: block;
      color: #fff;
      font-size: 20px;
      transform: rotate(-90deg);
    }
  }

  &__toggle-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: rgba($color: #555, $alpha: 0.75);
    border-radius: 4px;
    cursor: pointer;

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      font-size: 30px;
      transition: opacity 200ms ease-in-out;
    }

    &:before {
      content: "\003D";
      opacity: 1;
    }

    &:after {
      content: "\00D7";
      opacity: 0;
    }

    &.active:before {
      opacity: 0;
    }

    &.active:after {
      opacity: 1;
    }
  }

  &__navbar {
    margin: 0;
    z-index: 2;
    position: absolute;
    top: 64px;
    right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 1px rgba($color: #000, $alpha: 0.75);
    padding: 16px 32px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    li {
      list-style: none;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }

      a {
        display: block;
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        text-decoration: none;
        color: #000;
        transition: all 100ms ease-in-out;

        &.active {
          box-shadow: 0 1px #000;
        }
      }

      &.instagram-logo {
        display: none;
        img {
          display: block;
          width: 18px;
        }
      }

      &:not(.instagram-logo):hover a {
        color: #696969;
        box-shadow: 0 1px #696969;
      }
    }
  }

  &__toggle-button.active + &__navbar {
    pointer-events: initial;
    opacity: 1;
  }

  @include breakpoint(sm) {
    &__toggle-button {
      display: none;
    }

    &__navbar {
      position: static;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      box-shadow: none;
      padding: 0;
      pointer-events: initial;
      opacity: 1;

      li {
        margin-top: 0;
        margin-right: 32px;

        &:last-child {
          margin-right: 0;
        }

        &.instagram-link {
          display: none;
        }

        &.instagram-logo {
          display: block;
        }
      }
    }
  }

  &__footer {
    padding: 32px;
    padding-top: 96px;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 200;
      text-align: center;
    }
  }
}
