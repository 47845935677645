.project-slide {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  user-select: none;
  cursor: pointer;

  &__image {
    display: inline-flex;
    width: 100%;
    object-fit: cover;
  }
}
