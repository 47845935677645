@import "components/layout/variables.scss";

.page-projects {
  position: relative;
  padding: 40px 0;
  margin: 0;

  @include breakpoint(sm) {
    margin: auto 0 auto;
  }
}
