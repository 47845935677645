$background-color: #fff;

$screen: (
  xs: 0,
  sm: 670px,
  md: 990px,
  lg: 1280px,
  xl: 1440px,
);

@mixin breakpoint($name) {
  @media screen and (min-width: map-get($screen, $name)) {
    @content;
  }
}

$spacing: (
  sm: 8px,
  md: 16px,
  lg: 24px,
);

$justifyContent: (
  center,
  flex-start,
  flex-end,
  space-around,
  space-between,
  space-evenly
);

$alignItems: (center, flex-start, flex-end);
