@import "components/layout/variables.scss";

.grid {
  &-container {
    display: flex;
    flex-wrap: wrap;
  }

  &-item {
    display: block;
    flex-basis: calc(1 / 12) * 100%;
  }

  @each $value in (xs, sm, md, lg, xl) {
    @include breakpoint($value) {
      @for $i from 1 through 12 {
        &-#{$value}-#{$i} {
          flex-basis: calc($i / 12) * 100%;
        }
      }
    }
  }

  @each $name, $value in $spacing {
    &-spacing-#{$name} > &-item {
      padding: $value;
    }
  }

  @each $value in $justifyContent {
    &-justify-#{$value} {
      justify-content: $value;
    }
  }

  @each $value in $alignItems {
    &-align-#{$value} {
      align-items: $value;
    }
  }
}
