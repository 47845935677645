@import "components/layout/variables.scss";

$wrapperIn-duration: 200ms;
$fadeOut-duration: 400ms;
$contentIn-duration: 300ms;

$ease: ease-in-out forwards;

$wrapperIn-animation: wrapperIn $wrapperIn-duration $ease;
$fadeOut-animation: fadeOut $fadeOut-duration $ease;
$contentIn-animation: contentIn $contentIn-duration $wrapperIn-duration $ease;

.project-slider {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  user-select: none;
  width: 100vw;
  height: 100vh;
  padding: 70px 40px 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  animation: $wrapperIn-animation;

  opacity: 100;
  &--is-fading {
    animation: $wrapperIn-animation, $fadeOut-animation;
  }

  &__swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    opacity: 0;
    animation: $contentIn-animation;

    @include breakpoint(lg) {
      margin: 0 auto;
      width: calc(map-get($screen, lg) - 80px);
    }

    --swiper-theme-color: #fff;
    --swiper-navigation-size: 30px;

    .swiper-button-prev,
    .swiper-button-next {
      top: calc(50% + 10px);
    }
    .swiper-button-prev {
      left: -28px;
    }
    .swiper-button-next {
      right: -28px;
    }
  }

  &__swiper {
    width: 100%;
    height: 100%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__image-wrapper {
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    &__image {
      display: inline-flex;
      max-width: 100%;
      max-height: 100%;

      &.francisco {
        max-height: 584.52px;
      }
    }
  }

  &__close-button {
    position: absolute;
    top: -50px;
    right: 0;
    border: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: rgba($color: #555, $alpha: 0.5);
    border-radius: 4px;
    cursor: pointer;

    &:before {
      content: "\00D7";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;
      font-size: 30px;
    }
  }
}

@keyframes wrapperIn {
  100% {
    background-color: rgba($color: #000000, $alpha: 0.95);
  }
}

@keyframes fadeOut {
  100% {
    opacity: 0;
  }
}

@keyframes contentIn {
  100% {
    opacity: 100;
  }
}
